// Используем IIFE для изоляции контекста
(async () => {
    // Загрузка критичных модулей, которые нужны сразу при загрузке страницы
    document.addEventListener('DOMContentLoaded', async () => {
        const { ticker } = await import('./modules/animations/ticker.js');
        const { video } = await import('./modules/video-player.js');
        const { accordion } = await import('./modules/base/accordion.js');
        const { slider } = await import('./modules/slider.js');

        // Инициализация критичных функций
        ticker();
        video();
        accordion();
        slider();

        // Загрузка остальных модулей параллельно с основными
        const [inputsModule, anchorModule, modalsModule, serviceCardModule, profitModule, offersModule] = await Promise.all([
            import('./modules/inputs.js'),
            import('./modules/base/anchor.js'),
            import('./modules/base/modals.js'),
            import('./modules/service-card.js'),
            import('./modules/profit.js'),
            import('./modules/animations/offers.js')
        ]);

        // Инициализация остальных модулей
        inputsModule.inputs();
        anchorModule.anchor();
        modalsModule.modals();
        serviceCardModule.serviceCard();
        profitModule.profit();
        offersModule.offers();
    });
})();